<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="搜索条件">
        <el-input
          size="mini"
          v-model="formInline.user"
          placeholder="ID/昵称/手机号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="label">
      总人数：{{ total }}人 总发放红包：{{ consumeAmount }}元
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'center'
      }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="userNo" label="ID"> </el-table-column>
      <el-table-column prop="nickname" label="头像">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.avatar"
            :preview-src-list="[row.avatar]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="createTime" label="邀请时间"> </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getUserPage,
  getStoreFundsStatisticsByCurrentStore
} from '../../api/user'
export default {
  data() {
    return {
      total: 0,
      size: 20,
      current: 1,
      tableData: [],
      consumeAmount: '',
      formInline: {
        user: '',
        region: ''
      }
    }
  },
  created() {
    this.getUserPage()
    this.getStoreFundsStatisticsByCurrentStore()
  },
  methods: {
    search() {
      this.current = 1
      this.getUserPage()
    },
    async getStoreFundsStatisticsByCurrentStore() {
      const { data } = await getStoreFundsStatisticsByCurrentStore({})
      console.log(data)
      this.consumeAmount = data.consumeAmount || 0
    },
    async getUserPage() {
      const { data } = await getUserPage({
        pageSize: this.size,
        pageNum: this.current,
        searchKey: this.formInline.user
      })
      console.log(data)
      this.tableData = data.list
      this.total = data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      this.getUserPage()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getUserPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  background-color: #f2f2f2;
  line-height: 50px;
  border-left: 5px solid #0088ff;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  span {
    margin: 0 20px;
  }
}
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
